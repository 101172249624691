module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src3407454116/src/broad-gatsby/src/components/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Broad Mobile App","short_name":"The Broad","start_url":"/","background_color":"#000000","theme_color":"#ffffff","display":"standalone","icon":"src/images/BR_Favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"de50de4064699d1af6db40db1aa2d974"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-3488045-6","G-WXG46ZGFLK"],"head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/accessibility-guide","/architecture-tour","/art/*","/artistguides","/audio-tour","/audio","/collection","/explore-the-broad","/explore/*","/for-families","/general-questions-kids","/guides-maps","/kids-audio-tour/*","/look-at-art-together","/map","/reader","/scavenger-hunt","/tips-for-families","/tour-stops/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
